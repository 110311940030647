// Libraries
import React, {ReactElement, useEffect, useState} from 'react';
import {Col, Form, Modal, Popconfirm, Steps} from 'antd';
import dayjs from "dayjs";
import {RcFile} from "antd/lib/upload";
import {useSelector} from "react-redux";
import {CalendarOutlined, FileOutlined, ShopOutlined} from "@ant-design/icons";

// Types
import {DestinationType, TransportType} from "../../../types/transportTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import Details from "./Details";
import DateDetails from "./DateDetails";
import Files from "./Files";

// Utils
import {DEFAULT_DATETIME_FORMAT} from "../../../utils/constants";

// Store
import {useAppDispatch} from '../../../store/store';
import {addUnloadInfo} from "../../../store/redux/actions/transportActions";
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";


type PropsType = {
  destinations: DestinationType[];
  transportId: string | null;
  onToggleModal: (visible: boolean, transportId: string | null, destinations: DestinationType[]) => void;
};
const AddDetailFormModal = (props: PropsType) => {
  const [form] = Form.useForm<TransportType>();
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState<TransportType>({ transportDetails: [] });
  const [currentStep, setCurrentStep] = useState(0);
  const [modalKey, setModalKey] = useState(0);
  const [stepsRendered, setStepsRendered] = useState(false);
  const [files, setFiles] = useState<{ transportDestinationId?: string; fileList: RcFile[] }[]>([]);
  const [steps, setSteps] = useState<{ icon: JSX.Element, title: string, content: ReactElement }[]>([
    { title: 'Fechas', icon: <CalendarOutlined size={10} />, content: <DateDetails /> }
  ]);
  const { stores } = useSelector(transportSelectors.transportParamsSelector);
  const { destinations, transportId, onToggleModal } = props;
  const items = steps.map(item => ({ key: item.title, title: item.title, icon: item.icon }));

  useEffect(() => {
    const newSteps = steps;

    if (stepsRendered) {
      const filesStepIndex = newSteps.findIndex((step) => step.title === 'Archivos');

      if (filesStepIndex !== -1) {
        newSteps[filesStepIndex] = {
          ...newSteps[filesStepIndex],
          content: <Files destinations={destinations}
                          files={files}
                          addFile={addFile}
                          removeFile={removeFile}/>
        };
      }
    } else {
      destinations.map((destination) => {
        const destinationName = stores.find((store) => store.id === destination.destinationStoreId)?.value;

        newSteps.push({
          title: `${destinationName}`, icon: <ShopOutlined/>, content: <Details transportDestinationId={destination.transportDestinationId!} />
        })
      });

      newSteps.push({
        title: 'Archivos', icon: <FileOutlined />, content: <Files destinations={destinations}
                                                                   files={files}
                                                                   addFile={addFile}
                                                                   removeFile={removeFile} />
      });
    }

    setSteps(newSteps);
    setModalKey(modalKey + 1);
    setStepsRendered(true);
  }, [destinations, files]);

  useEffect(() => {
    const newFiles = [...files];

    destinations.map((destination) => {
      newFiles.push({
        transportDestinationId: destination.transportDestinationId,
        fileList: [],
      })
    });

    setFiles(newFiles);
  }, [destinations]);

  const nextStep = async () => {
    try {
      const values = await form.validateFields();

      if (currentStep === 0) {
        setFormValues({
          ...formValues,
          arrivalDate: values.arrivalDate,
          loadingStartDate: values.loadingStartDate,
          loadingEndDate: values.loadingEndDate
        });
      } else {
        const newTransportDetails = formValues.transportDetails!;
        const existingDetail = newTransportDetails.find((i) => i.transportDestinationId === Object.keys(values)[0]);
        const details: any = Object.values(values)[0];

        if (existingDetail === undefined) {
          newTransportDetails.push({
            ...details,
            transportDestinationId: Object.keys(values)[0]
          });
        } else {
          const index = newTransportDetails.indexOf(existingDetail);
          newTransportDetails[index] = { ...existingDetail, ...details };
        }

        setFormValues({
          ...formValues,
          transportDetails: newTransportDetails,
        });
      }
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error('Error de validación:', error);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const addFile = (file: RcFile, transportDestinationId?: string) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      const existingElement = newFiles.find((f) => f.transportDestinationId === transportDestinationId);

      if (existingElement) {
        existingElement.fileList = [...existingElement.fileList, file];
      }

      return newFiles;
    });
  }

  const removeFile = (fileIndex: number, transportDestinationId?: string) => {
    const newFiles = [...files];
    const existingElement = newFiles.find((f) => f.transportDestinationId === transportDestinationId);
    if (existingElement) {
      existingElement.fileList = existingElement.fileList.filter((_, index) => index !== fileIndex);
    }

    setFiles(newFiles);
  }

  const handleSubmit = () => {
    const newValues = formValues;

    newValues.transportEngineId = transportId ?? '';
    newValues.arrivalTime = dayjs(newValues.arrivalDate).format(DEFAULT_DATETIME_FORMAT);
    newValues.arrivalDate = undefined;
    newValues.loadingStartTime = dayjs(newValues.loadingStartDate).format(DEFAULT_DATETIME_FORMAT);
    newValues.loadingStartDate = undefined;
    newValues.loadingEndTime = dayjs(newValues.loadingEndDate).format(DEFAULT_DATETIME_FORMAT);
    newValues.loadingEndDate = undefined;

    dispatch(addUnloadInfo(newValues, files));
    onToggleModal(false, null, []);
  }

  return (
    <Modal title={`Agregar detalle de cada tienda`}
           key={modalKey}
           width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, null, [])} />,
             currentStep > 0 && (
               <ButtonComponent key={'previous'}
                                text={'Anterior'}
                                className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                  'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                                onClick={prevStep} />
             ),
             currentStep < steps.length - 1 && (
               <ButtonComponent key={'next'}
                                text={`Siguiente`}
                                className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                  'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                                onClick={nextStep}/>
             ),
             currentStep === steps.length - 1 && (
               <Popconfirm key={'submit'}
                           title={'¿Confirma el envío de la información?'}
                           okText={'Confirmar'}
                           onConfirm={() => form.submit()}>
                 <ButtonComponent text={`Enviar`}
                                  className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                    'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
               </Popconfirm>
             ),
           ]}>
      <>
        <Steps current={currentStep}
               size={'small'}
               items={items} />

        <Form layout={"vertical"}
              form={form}
              onFinish={currentStep < steps.length - 1 ? nextStep : handleSubmit}>
          <Col className={"mt-4 py-4"}>
            {steps[currentStep].content}
          </Col>
        </Form>
      </>
    </Modal>
  );
}

export default AddDetailFormModal;