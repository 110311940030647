// Libraries
import React, {useEffect, useState} from 'react';
import {Button, Col, Drawer, message, Popconfirm, Space, Tooltip, Upload} from 'antd';
import {RcFile} from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import {DeleteOutlined, FileAddOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Types
import {DestinationType} from "../../../types/transportTypes";

// Components
import InputComponent from "../../subComponents/InputComponent";
import ButtonComponent from "../../subComponents/ButtonComponent";
import {ERROR_MODAL} from "../../subComponents/responseModals";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";
import {isEmpty} from "../../../utils/functions";
import {addFiles} from "../../../store/redux/actions/transportActions";
import {useAppDispatch} from "../../../store/store";


type PropsType = {
  transportId: string | null;
  destinationStoreId: string;
  destinations: DestinationType[];
  setDrawerOpen: (open: boolean) => void;
}
const AddFilesDrawer = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const {transportId, destinationStoreId, destinations, setDrawerOpen} = props;
  const { stores } = useSelector(transportSelectors.transportParamsSelector);
  const [files, setFiles] = useState<{ transportDestinationId?: string; fileList: RcFile[] }>({ fileList: [] });
  const acceptedFormats = "application/pdf,image/jpeg,image/png,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword";
  const storeName = stores.find((s) => s.id === destinationStoreId)?.value;
  const { transportDetailId } = destinations.find((d) => d.destinationStoreId === destinationStoreId)!.transportDetail!;

  useEffect(() => {
    const newDestination =
      destinations.find((destination) => destination.destinationStoreId === destinationStoreId);
    const newFiles = {
      transportDestinationId: newDestination?.transportDestinationId,
      fileList: [],
    };

    setFiles(newFiles);
  }, [destinations]);

  const beforeUpload = (file: RcFile, size: number) => {
    const isLessToSize = file.size / 1024 / 1024 < size;
    if (!isLessToSize) {
      message.error(`El archivo debe ser menor o igual a ${size}MB`);
    }
    return isLessToSize;
  }

  const dummyRequest = (options: UploadRequestOption) => {
    const { file, onSuccess, onError } = options;

    if (!acceptedFormats.includes((file as RcFile).type)) {
      ERROR_MODAL("Este tipo de archivo no está permitido.");
      setTimeout(() => {
        onError?.(new Error("Formato inválido"));
      }, 0);
      return;
    }

    setTimeout(() => {
      addFile(file as RcFile);
      onSuccess?.("ok");
    }, 0);
  };

  const addFile = (file: RcFile) => {
    setFiles((prevFiles) => {
      let newFileList = [...prevFiles.fileList];
      newFileList = [...newFileList, file];

      return {
        ...prevFiles,
        fileList: newFileList
      };
    });
  }

  const removeFile = (fileIndex: number) => {
    setFiles((prevFiles) => {
      let newFileList = [...prevFiles.fileList];
      newFileList = newFileList.filter((_, index) => index !== fileIndex);

      return {
        ...prevFiles,
        fileList: newFileList
      };
    });
  }

  const handleSubmit = () => {
    dispatch(addFiles(transportDetailId!, transportId!, files));
    setDrawerOpen(false);
  }

  return (
    <Drawer title={`Agregar archivos para ${storeName}`}
            width={500}
            open={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            extra={
              <Space>
                <Popconfirm title={'¿Confirmas que deseas cancelar el cargue de archivos?'}
                            okText={'Confirmar'}
                            onConfirm={() => setDrawerOpen(false)}>
                  <Button>Cancelar</Button>
                </Popconfirm>
                {!isEmpty(files.fileList) &&
                  <Popconfirm title={`¿Confirmas cargar estos archivos para ${storeName}?`}
                              okText={'Confirmar'}
                              onConfirm={handleSubmit}>
                    <Button type="primary">
                      Cargar
                    </Button>
                  </Popconfirm>
                }
              </Space>
            }
    >
      <Col className={'gap-y-3'}>
        {files.fileList.map((file, index) => {
            return <Col key={index} className={'flex gap-x-3 mb-4'}>
              <InputComponent value={file.name}
                              title={file.name}
                              disabled={true}
                              readOnly={true}
                              className={'bg-white text-grey-dkt-600'}/>
              <Tooltip title={'Quitar archivo'}>
                <DeleteOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                                onClick={() => removeFile(index)}/>
              </Tooltip>
            </Col>
          }
        )}

        <Col className={'text-center w-full'}>
          <Upload showUploadList={false}
                  multiple={false}
                  accept={acceptedFormats}
                  beforeUpload={(file) => beforeUpload(file, 15)}
                  customRequest={(options) => dummyRequest(options)}
          >
            <ButtonComponent text={'Agregar archivo'}
                             icon={<FileAddOutlined />}
                             className={'h-11 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                               'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'} />
          </Upload>
        </Col>
      </Col>
    </Drawer>
  );
}

export default AddFilesDrawer;
