// Libraries
import React, {useEffect} from 'react';
import {Modal, Collapse} from 'antd';
import {useAppDispatch} from '../../../store/store';
import {useSelector} from "react-redux";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";
import {getDetail} from "../../../store/redux/actions/transportActions";

// Components
import ButtonComponent from "../../subComponents/ButtonComponent";
import GeneralInformation from "./GeneralInformation";
import CarrierInformation from "./CarrierInformation";
import SpecificInformation from "./SpecificInformation";
import ReturnInformation from "./ReturnInformation";
import CancelInformation from './CancelInformation';

// Utils
import {isEmpty} from "../../../utils/functions";
import {TRANSPORT_STATES} from "../../../utils/constants";

const { Panel } = Collapse;


type PropsType = {
  transportId: string | null;
  onToggleModal: (visible: boolean, transportId: string | null) => void;
};
const DetailModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { transportId, onToggleModal } = props;
  const detail = useSelector(transportSelectors.transportDetailSelector);

  useEffect(() => {
    transportId && dispatch(getDetail(transportId));
  }, [transportId]);

  return (
    <Modal title={`Detalle del servicio`}
           width={900}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, null)} />,
           ]}>
      <Collapse defaultActiveKey={['1']} className={'[&>div>div.ant-collapse-header]:font-semibold'}>
        <Panel header={"Información general"} key={"1"}>
          <GeneralInformation />
        </Panel>

        {detail.carrierResponse?.carPlate &&
          <Panel header={"Transportista"} key={"2"}>
            <CarrierInformation />
          </Panel>
        }

        {(detail.state === TRANSPORT_STATES.enCamino || detail.state === TRANSPORT_STATES.finalizado) &&
          <Panel header={"Detalles específicos"} key={"3"}>
            <SpecificInformation transportId={transportId} />
          </Panel>
        }

        {(!isEmpty(detail.returns) && (detail.state != TRANSPORT_STATES.cancelado)) &&
          <Panel header={"Detalles del retorno"} key={"4"}>
            <ReturnInformation />
          </Panel>
        }

        {(detail.state === TRANSPORT_STATES.cancelado) &&
          <Panel header={"Cancelación"} key={"5"}>
            <CancelInformation />
          </Panel>
        }
      </Collapse>
    </Modal>
  );
}

export default DetailModal;
