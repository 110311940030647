// Libraries
import React from 'react';
import {Col} from 'antd';

// Components
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';

// Utils
import {isEmpty} from "../../../utils/functions";

// Constants
import {formFields} from "./formFields";


type PropsType = {
  transportDestinationId: string;
}
const Details = (props: PropsType) => {
  const { transportDestinationId } = props;

  return (
    <Col className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
      {formFields.map((field) => {
        if (field.type === 'string') {
          return <FormItemComponent key={field.name}
                                    name={[transportDestinationId, field.name]}
                                    label={field.label}
                                    required={true}
                                    newRules={
                                      [
                                        () => ({
                                          validator(_, value) {
                                            if (field.name === 'ccShipments') {
                                              const alphanumericRegex = /^[a-zA-Z0-9]*$/;
                                              if (alphanumericRegex.test(value)) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject(new Error('El valor debe ser alfanumérico'));
                                            } else {
                                              if (!isEmpty(value)) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject();
                                            }
                                          },
                                        })
                                      ]
                                    }
                                    child={<InputComponent />}
          />
        } else if (field.type !== 'date') {
          return <FormItemComponent key={field.name}
                                    name={[transportDestinationId, field.name]}
                                    label={field.label}
                                    required={true}
                                    newRules={
                                      [
                                        ({ setFieldValue }) => ({
                                          validator(_, value) {
                                            if (!isNaN(parseInt(value))) {
                                              setFieldValue([transportDestinationId, field.name], parseInt(value));
                                              return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('El valor debe ser numérico'));
                                          },
                                        })
                                      ]
                                    }
                                    child={<InputComponent />}
          />
        }
      })}
      <FormItemComponent key={'observations'}
                         name={[transportDestinationId, 'observations']}
                         label={'Observaciones'}
                         required={false}
                         child={<InputComponent type={'textarea'} />}
      />
    </Col>
  );
}

export default Details;