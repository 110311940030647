// Libraries
import {Descriptions} from 'antd';

// Utils
import {useSelector} from "react-redux";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";


const CancelInformation = () => {
  const detail = useSelector(transportSelectors.transportDetailSelector);
  
  return (
    <Descriptions bordered
                  column={2}
                  size={'small'}>
      <Descriptions.Item label="Motivo" span={2} className={'w-44'}>
        {detail.cancellationReason}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default CancelInformation;
