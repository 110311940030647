// Libraries
import React from 'react';
import {Row, DatePicker} from 'antd';

// Components
import FormItemComponent from '../../subComponents/FormItemComponent';

// Utils
import {DATETIME_FORMAT} from "../../../utils/constants";

// Constants
import {formFields} from "./formFields";


const Details = () => {
  return (
    <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
      {formFields.map((field) => {
        if(field.type === 'date') {
          return <FormItemComponent key={field.name}
                                    name={field.name}
                                    label={field.label}
                                    required={true}
                                    child={<DatePicker showTime
                                                       className={'w-full h-9 rounded-md border border-grey-dkt-300'}
                                                       format={DATETIME_FORMAT} />}
          />
        }
      })}
    </Row>
  );
}

export default Details;