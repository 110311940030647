import React, { useState } from 'react';
import {Row, Col} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import TransportTable from './TransportTable';
import TransportFormModal from './TransportFormModal';
import CarrierResponseFormModal from "./CarrierResponseFormModal";
import DetailModal from "./detailModal/DetailModal";
import AddDetailFormModal from "./detailFormModal/AddDetailFormModal";
import ReturnDetailFormModal from "./returnDetailFormModal/ReturnDetailFormModal";
import ReasonFormModal from "./ReasonFormModal";
import ReassignCarrierFormModal from "./ReassignCarrierFormModal";

// Utils
import {getLocalStorageItem} from "../../utils/functions";
import {ROLES} from "../../utils/constants";
import {routes} from "../../utils/routes";

// Types
import {DestinationType} from "../../types/transportTypes";


const Transport = () => {
  const [transportId, setTransportId] = useState<string | null>(null);
  const [destinations, setDestinations] = useState<DestinationType[]>([]);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [carrierResponseModalVisible, setCarrierResponseModalVisible] = useState(false);
  const [addDetailModalVisible, setAddDetailModalVisible] = useState(false);
  const [returnDetailModalVisible, setReturnDetailModalVisible] = useState(false);
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const [reassignCarrierModalVisible, setReassignCarrierModalVisible] = useState(false);
  const renderRole = getLocalStorageItem('renderRole');
  const allowedModules = ROLES[renderRole as keyof typeof ROLES].allowedModules as any[];
  const permissions = allowedModules.find(module => module.name === routes.transport)!.permissions!;

  const handleModalVisible = (visible: boolean) => {
    setFormModalVisible(visible);
  };

  const handleCarrierResponseModalVisible = (visible: boolean, transportId: string | null) => {
    setCarrierResponseModalVisible(visible);
    setTransportId(transportId);
  };

  const handleAddDetailModalVisible = (visible: boolean, transportId: string | null, destinations: DestinationType[]) => {
    setAddDetailModalVisible(visible);
    setTransportId(transportId);
    setDestinations(destinations);
  };

  const handleDetailModalVisible = (visible: boolean, transportId: string | null) => {
    setDetailModalVisible(visible);
    setTransportId(transportId);
  };

  const handleReturnDetailModalVisible = (visible: boolean, transportId: string | null) => {
    setReturnDetailModalVisible(visible);
    setTransportId(transportId);
  };

  const handleReasonModalVisible = (visible: boolean, transportId: string | null, isCancelling: boolean) => {
    setIsCancelling(isCancelling);
    setReasonModalVisible(visible);
    setTransportId(transportId);
  };

  const handleReassignCarrierModalVisible = (visible: boolean, transportId: string | null) => {
    setReassignCarrierModalVisible(visible);
    setTransportId(transportId);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Servicios'}
                        className={'font-semibold text-gray-900'} />
      </Col>

      {permissions.canAdd &&
        <Col md={10} sm={10} xs={24} className={'text-right'}>
          <ButtonComponent text={'Crear servicio'}
                           icon={<PlusOutlined />}
                           className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                             'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                           onClick={() => handleModalVisible(true)}/>
        </Col>
      }

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <TransportTable onToggleDetailModal={handleDetailModalVisible}
                        onToggleCarrierResponseModal={handleCarrierResponseModalVisible}
                        onToggleAddDetailModal={handleAddDetailModalVisible}
                        onToggleReturnDetailModal={handleReturnDetailModalVisible}
                        onToggleReasonModal={handleReasonModalVisible}
                        onToggleReassignCarrierModalVisible={handleReassignCarrierModalVisible} />
      </Col>

      {detailModalVisible &&
        <DetailModal transportId={transportId}
                     onToggleModal={handleDetailModalVisible} />
      }
      {formModalVisible &&
        <TransportFormModal onToggleModal={handleModalVisible} />
      }
      {carrierResponseModalVisible &&
        <CarrierResponseFormModal transportId={transportId}
                                  onToggleModal={handleCarrierResponseModalVisible} />
      }
      {addDetailModalVisible &&
        <AddDetailFormModal transportId={transportId}
                            destinations={destinations}
                            onToggleModal={handleAddDetailModalVisible} />
      }
      {returnDetailModalVisible &&
        <ReturnDetailFormModal transportId={transportId}
                               onToggleModal={handleReturnDetailModalVisible} />
      }
      {reasonModalVisible &&
        <ReasonFormModal isCancelling={isCancelling}
                         transportId={transportId}
                         onToggleModal={handleReasonModalVisible} />
      }
      {reassignCarrierModalVisible &&
        <ReassignCarrierFormModal transportId={transportId}
                                  onToggleModal={handleReassignCarrierModalVisible} />
      }
    </Row>
  );
}

export default Transport;
