// Libraries
import React from 'react';
import {Row, Form, Modal} from 'antd';
import {useAppDispatch} from '../../store/store';

// Store
import {cancel, finish} from "../../store/redux/actions/transportActions";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type PropsType = {
  isCancelling: boolean;
  transportId: string | null;
  onToggleModal: (visible: boolean, transportId: string | null, isCancelling: boolean) => void;
};
const ReasonFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isCancelling, transportId, onToggleModal } = props;

  const handleSubmit = (data: { observations: string }) => {
    if (isCancelling) {
      dispatch(cancel(transportId!, data));
    } else {
      dispatch(finish(transportId!, data));
    }
    onToggleModal(false, null, false);
  }

  return (
    <Modal title={isCancelling ? `Razón de la cancelación` : 'Novedades'}
           width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, null, false)} />,

             <ButtonComponent key={'submit'} text={`Enviar`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        {
          isCancelling
            ? 'Por favor ingrese la justificación de la cancelación de este servicio:'
            : 'Por favor ingrese las observaciones de la finalización de este servicio:'
        }

        <Row className={'mt-5'}>
          <FormItemComponent name={'observations'}
                             label={isCancelling ? 'Justificación' : 'Observaciones'}
                             required={true}
                             className={'w-full'}
                             child={<InputComponent type={'textarea'} />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default ReasonFormModal;
