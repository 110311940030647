import {RcFile} from "antd/lib/upload";

// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import transportServices from '../../../api/transportServices';

// Types
import {transportTypes} from '../types';
import {
  CarrierResponseType,
  ReturnDetailType,
  TransportPaginationType,
  TransportType
} from "../../../types/transportTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Utils
import {base64ToFile} from "../../../utils/functions";


export const getParams = (data: TransportPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.getParams()
    .then((response) => {
      dispatch({
        type: transportTypes.GET_TRANSPORT_PARAMS,
        params: response.data
      });
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const getAll = (data: TransportPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.getAll(data)
    .then((response) => {
      dispatch({
        type: transportTypes.GET_ALL_TRANSPORTS,
        transportResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const getDetail = (transportId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: transportTypes.GET_TRANSPORT_DETAIL,
    detail: {}
  });
  transportServices.getDetail(transportId)
    .then((response) => {
      dispatch({
        type: transportTypes.GET_TRANSPORT_DETAIL,
        detail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      dispatch(setLoading(false));
      ERROR_MODAL(err.data.message);
    })
}
export const getCarrierResponse = (transportId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: transportTypes.GET_CARRIER_RESPONSE,
    carrierResponse: {}
  });
  transportServices.getCarrierResponse(transportId)
    .then((response) => {
      dispatch({
        type: transportTypes.GET_CARRIER_RESPONSE,
        carrierResponse: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      dispatch(setLoading(false));
      ERROR_MODAL(err.data.message);
    })
}
export const create = (
  transport: TransportType, 
  callback?: (dispatch: AppDispatch) => Promise<void>
) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.create(transport)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      if(callback){
        dispatch(callback);
      } else {
        dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
      }
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const downloadFile = (fileId?: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.downloadFile(fileId)
    .then((response) => {
      base64ToFile(response.data.data, response.data.url);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const addFiles = (
  transportDetailId: string,
  transportId: string,
  transportDetail: { transportDestinationId?: string; fileList: RcFile[] }
) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  try {
    const urls: string[] = [];

    for (const file of transportDetail.fileList) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      const response = await transportServices.uploadFile(formData);
      urls.push(response.data.url);
    }


    transportServices.addFiles(transportDetailId, urls)
      .then((response) => {
        SUCCESS_MODAL(response.data);
        dispatch(getDetail(transportId));
      })
      .catch(err => {
        ERROR_MODAL(err.data.message);
        dispatch(setLoading(false));
      })
  } catch (err: any) {
    ERROR_MODAL(err.data.message);
    dispatch(setLoading(false));
  }
}
export const authorizedEntry = (transportId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.authorizedEntry(transportId)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const atOriginTerminal = (transportId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.atOriginTerminal(transportId)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const finish = (transportId: string, data: { observations: string }) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.finish(transportId, data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const cancel = (transportId: string, data: { observations: string }) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.cancel(transportId, data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const reassignCarrier = (data: { transportEngineId: string, carrierId: string; reason: string; }) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.reassignCarrier(data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    });
}
export const addCarrierResponse = (response: CarrierResponseType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.addCarrierResponse(response)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    });
}
export const updateCarrierResponse = (carrierResponseId: string, response: CarrierResponseType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.updateCarrierResponse(carrierResponseId, response)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    });
}
export const addUnloadInfo = (
  detail: TransportType,
  files: {transportDestinationId?: string | undefined, fileList: RcFile[]}[]
) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  try {
    for (const file of files) {
      const transportDetailFiles: { url: string }[] = [];
      for (const f of file.fileList) {
        const formData = new FormData();
        formData.append('file', f, f.name);

        const response = await transportServices.uploadFile(formData);
        transportDetailFiles.push({
          url: response.data.url
        });
      }

      const transportDestinationFound = 
        detail.transportDetails?.find((transportDetail) => transportDetail.transportDestinationId === file.transportDestinationId);
      transportDestinationFound!.files = transportDetailFiles;
    }

    transportServices.addUnloadInfo(detail)
      .then((response) => {
        SUCCESS_MODAL(response.data);
        dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
      })
      .catch(err => {
        ERROR_MODAL(err.data.message);
        dispatch(setLoading(false));
      });
  } catch (err: any) {
    ERROR_MODAL(err.data.message);
    dispatch(setLoading(false));
  }
}
export const addReturnDetail = (detail: ReturnDetailType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  transportServices.addReturnDetail(detail)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ pagination: { page: 1, size: 10 }, filter: { } }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    });
}
