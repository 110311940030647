// Libraries
import React, {useEffect, useState} from 'react';
import {Col, Descriptions, Tabs, Tooltip} from 'antd';
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {DownloadOutlined, PaperClipOutlined} from "@ant-design/icons";

// Utils
import {DATETIME_FORMAT} from "../../../utils/constants";
import {getLocalStorageItem} from "../../../utils/functions";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";
import {useAppDispatch} from "../../../store/store";
import {downloadFile} from "../../../store/redux/actions/transportActions";

// Components
import InputComponent from "../../subComponents/InputComponent";
import ButtonComponent from "../../subComponents/ButtonComponent";
import AddFilesDrawer from "./AddFilesDrawer";

// Types
import {DestinationType} from "../../../types/transportTypes";


type PropsType = {
  transportId: string | null;
};
const SpecificInformation = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { transportId } = props;
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [destinationStoreIdToUploadFile, setDestinationStoreIdToUploadFile] = useState<string>('');
  const [destinations, setDestinations] = useState<DestinationType[]>([]);
  const { stores } = useSelector(transportSelectors.transportParamsSelector);
  const detail = useSelector(transportSelectors.transportDetailSelector);
  const renderRole = getLocalStorageItem('renderRole');
  const isDecatruckUser = renderRole === 'decatruckUser';


  useEffect(() => {
    const newDestinations: DestinationType[] = [];

    detail.destinations?.map((destination) => {
      newDestinations.push(destination);
    });
    const transportDetails = detail.destinations?.map((destination) => destination.transportDetail);
    const sealNumbers = detail.destinations?.map((destination) => destination.sealNumber);
    const newSealNumbers = sealNumbers!.reduce((acc: string, sealNumber: any) => {
      acc = acc ? `${acc} - ${sealNumber}` : sealNumber;

      return acc;
    }, '');
    const totals = transportDetails!.reduce((acc: any, detailItem: any) => {
      for (const key in detailItem) {
        const value = detailItem[key];

        if (typeof value === "number") {
          acc[key] = (acc[key] || 0) + value;
        } else if (typeof value === "string") {
          acc[key] = acc[key] ? `${acc[key]} - ${value}` : value;
        } else if (Array.isArray(value)) {
          acc[key] = [];
        }
      }
      return acc;
    }, {});

    newDestinations.push({
      transportDestinationId: '', destinationStoreId: '1', sealNumber: newSealNumbers, restrictions: '', serial: '', unloadDateTime: '',
      transportDetail: totals
    });
    setDestinations(newDestinations);
  }, [detail]);

  const getTabs = () => {
    const tabs: { label: string, key: string, children: JSX.Element }[] = [];

    destinations.map((destination) => {
      tabs.push({
        label: `${destination.destinationStoreId === '1' ? 'Consolidado' : getStoreNameById(destination.destinationStoreId)}`,
        key: destination.destinationStoreId,
        children:
          <span>
            <Descriptions bordered column={2} size={'small'}>
              {destination.destinationStoreId !== '1' &&
                <Descriptions.Item label={"Número de expedición"}>{destination.transportDetail?.dispatchNumber}</Descriptions.Item>
              }
              {destination.destinationStoreId !== '1' &&
                <Descriptions.Item label={"Número de UAT"}>{destination.transportDetail?.uatNumber}</Descriptions.Item>
              }
              <Descriptions.Item label={"Número de cajas"}>{destination.transportDetail?.numberOfBoxes}</Descriptions.Item>
              <Descriptions.Item label={"Número de pallets"}>{destination.transportDetail?.numberOfPallets}</Descriptions.Item>
              <Descriptions.Item label={"Cajas a granel"}>{destination.transportDetail?.bulkBoxes}</Descriptions.Item>
              <Descriptions.Item label={"Cantidades enviadas"}>{destination.transportDetail?.sentQuantities}</Descriptions.Item>
              <Descriptions.Item label={"Intertiendas"}>{destination.transportDetail?.interstoreShipments}</Descriptions.Item>
              <Descriptions.Item label={"Dekapro"}>{destination.transportDetail?.dekaproShipments}</Descriptions.Item>
              {destination.destinationStoreId !== '1' &&
                <Descriptions.Item label={"C&C"}>{destination.transportDetail?.ccShipments}</Descriptions.Item>
              }
              <Descriptions.Item label={"Envíos sin flujos"}>{destination.transportDetail?.shipmentsWithoutFlows}</Descriptions.Item>
              <Descriptions.Item label={"Reclamaciones"}>{destination.transportDetail?.claims}</Descriptions.Item>
              <Descriptions.Item label={"Publicidad"}>{destination.transportDetail?.advertising}</Descriptions.Item>
              <Descriptions.Item label={"Agenciamiento"}>{destination.transportDetail?.agencyFees}</Descriptions.Item>
              <Descriptions.Item label={"Canastas"}>{destination.transportDetail?.baskets}</Descriptions.Item>
              <Descriptions.Item label={"Dollies"}>{destination.transportDetail?.dollies}</Descriptions.Item>
              <Descriptions.Item label={"Tapas"}>{destination.transportDetail?.lids}</Descriptions.Item>
              <Descriptions.Item label={"Magnus"}>{destination.transportDetail?.magnusShipments}</Descriptions.Item>
              {destination.destinationStoreId !== '1' &&
                <Descriptions.Item label={"Observaciones"}>{destination.transportDetail?.observations}</Descriptions.Item>
              }
              {destination.destinationStoreId !== '1' &&
                <Descriptions.Item label={"Números de precinto"}>{destination.sealNumber}</Descriptions.Item>
              }
            </Descriptions>

            {destination.destinationStoreId !== '1' &&
              <Col className={'grid grid-cols-2 gap-x-5 gap-y-4'}>
                <Col className={'grid grid-cols-2 col-span-2'}>
                  <span className={'mt-4 font-bold'}>Archivos:</span>

                  {isDecatruckUser &&
                    <Col className={'text-right w-full'}>
                      <ButtonComponent icon={<PaperClipOutlined />}
                                       className={'w-fit mt-4'}
                                       onClick={() => {
                                         setDestinationStoreIdToUploadFile(destination.destinationStoreId);
                                         setDrawerOpen(true);
                                       }}
                                       text={'Adjuntar otros archivos'} />
                    </Col>
                  }
                </Col>

                {destination.transportDetail?.files && destination.transportDetail?.files.length > 0
                  &&
                  destination.transportDetail?.files?.map((file) =>
                    <Col key={file.url} className={'flex gap-x-3'}>
                      <InputComponent value={file.url}
                                      title={file.url}
                                      disabled={true}
                                      readOnly={true}
                                      className={'bg-white text-grey-dkt-600'} />
                      <Tooltip title={'Descargar archivo'}>
                        <DownloadOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                                          onClick={() => dispatch(downloadFile(file.fileId))} />
                      </Tooltip>
                    </Col>
                  )
                }
              </Col>
            }
          </span>
      });
    });

    return tabs;
  };

  const getStoreNameById = (storeId: string) => {
    return stores.find((store) => store.id === storeId)?.value;
  }

  return (
    <>
      <Descriptions bordered
                    column={2}
                    size={'small'}>
        <Descriptions.Item label={"Hora anuncio de cargue"}>
          {dayjs(detail.arrivalTime).format(DATETIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={"Hora inicio de cargue"}>
          {dayjs(detail.loadingStartTime).format(DATETIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={"Hora fin de cargue"}>
          {dayjs(detail.loadingEndTime).format(DATETIME_FORMAT)}
        </Descriptions.Item>
      </Descriptions>

      <h1 className={'font-bold my-4'}>Tiendas destino:</h1>

      <Tabs type={"card"}
            items={getTabs()}
      />

      {drawerOpen &&
        <AddFilesDrawer transportId={transportId}
                        destinations={destinations}
                        destinationStoreId={destinationStoreIdToUploadFile}
                        setDrawerOpen={setDrawerOpen} />
      }
    </>
  );
}

export default SpecificInformation;
