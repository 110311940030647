// Libraries
import React from 'react';
import {Col, Collapse, message, Row, Tooltip, Upload} from 'antd';
import {DeleteOutlined, FileAddOutlined} from "@ant-design/icons";
import {RcFile} from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import {useSelector} from "react-redux";

// Components
import ButtonComponent from "../../subComponents/ButtonComponent";
import {ERROR_MODAL} from "../../subComponents/responseModals";
import InputComponent from "../../subComponents/InputComponent";

// Types
import {DestinationType} from "../../../types/transportTypes";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";

const { Panel } = Collapse;


type PropsType = {
  destinations: DestinationType[];
  files: { transportDestinationId?: string; fileList: RcFile[] }[];
  addFile:  (file: RcFile, transportDestinationId?: string) => void;
  removeFile:  (fileIndex: number, transportDestinationId?: string) => void;
}
const Files = (props: PropsType) => {
  const { destinations, files, addFile, removeFile } = props;
  const { stores } = useSelector(transportSelectors.transportParamsSelector);
  const acceptedFormats = "application/pdf,image/jpeg,image/png,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword";

  const getFileList = (transportDestinationId?: string) => {
    return files.find((f) => f.transportDestinationId === transportDestinationId)?.fileList
  }

  const beforeUpload = (file: RcFile, size: number) => {
    const isLessToSize = file.size / 1024 / 1024 < size;
    if (!isLessToSize) {
      message.error(`El archivo debe ser menor o igual a ${size}MB`);
    }
    return isLessToSize;
  }

  const dummyRequest = (options: UploadRequestOption, transportDestinationId?: string) => {
    const { file, onSuccess, onError } = options;

    if (!acceptedFormats.includes((file as RcFile).type)) {
      ERROR_MODAL("Este tipo de archivo no está permitido.");
      setTimeout(() => {
        onError?.(new Error("Formato inválido"));
      }, 0);
      return;
    }

    setTimeout(() => {
      addFile(file as RcFile, transportDestinationId);
      onSuccess?.("ok");
    }, 0);
  };

  return (
    <Row className={'w-full grid grid-cols-1 gap-y-4'}>
      Si es necesario, puedes adjuntar archivos a cada tienda:

      <Collapse defaultActiveKey={destinations.map((destination) => destination.destinationStoreId)}>
        {destinations.map((destination) =>
          <Panel header={stores.find((store) => store.id === destination.destinationStoreId)?.value}
                 key={destination.destinationStoreId}>
            <Col className={'grid grid-cols-2 gap-x-2 gap-y-3'}>
              {getFileList(destination.transportDestinationId)?.map((file, index) => {
                return <Col key={index} className={'flex gap-x-3'}>
                    <InputComponent value={file.name}
                                    title={file.name}
                                    disabled={true}
                                    readOnly={true}
                                    className={'bg-white text-grey-dkt-600'}/>
                    <Tooltip title={'Quitar archivo'}>
                      <DeleteOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                                      onClick={() => removeFile(index, destination.transportDestinationId)}/>
                    </Tooltip>
                  </Col>
                }
              )}

              <Col className={'text-center w-full'}>
                <Upload showUploadList={false}
                        multiple={false}
                        accept={acceptedFormats}
                        beforeUpload={(file) => beforeUpload(file, 15)}
                        customRequest={(options) => dummyRequest(options, destination.transportDestinationId)}
                >
                  <ButtonComponent text={'Agregar archivo'}
                                   icon={<FileAddOutlined />}
                                   className={'h-11 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                     'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'} />
                </Upload>
              </Col>
            </Col>
          </Panel>
        )}
      </Collapse>
    </Row>
  );
}

export default Files;