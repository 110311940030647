// Libraries
import React, {useEffect, useState} from 'react';
import {Descriptions, Col, Space} from 'antd';
import dayjs from "dayjs";

// Utils
import {DATETIME_FORMAT} from "../../../utils/constants";
import {useSelector} from "react-redux";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";

// Types
import {DestinationType} from "../../../types/transportTypes";


const GeneralInformation = () => {
  const [destinationList, setDestinationList] = useState<DestinationType[]>([]);
  const { stores, states } = useSelector(transportSelectors.transportParamsSelector);
  const detail = useSelector(transportSelectors.transportDetailSelector);

  useEffect(() => {
    if(detail.destinations) {
      const newDestinationList: DestinationType[] = [];
      detail.destinations.map((destination) => {
        newDestinationList.push({
          ...destination,
          unloadDateTime: dayjs(destination.unloadDateTime).format(DATETIME_FORMAT),
          destinationStoreId: stores.find((store) => store.id === destination.destinationStoreId)?.value as string
        })
      });

      setDestinationList(newDestinationList);
    }
  }, [detail]);
  
  return (
    <Descriptions bordered
                  column={2}
                  size={'small'}>
      <Descriptions.Item label="Fecha de cargue">{dayjs(detail.startDatetime).format(DATETIME_FORMAT)}</Descriptions.Item>
      <Descriptions.Item label="Estado">{states.find((state) => state.id === detail.state)?.value}</Descriptions.Item>
      <Descriptions.Item label="Con retorno">{detail.withReturn ? 'SI' : 'NO'}</Descriptions.Item>
      <Descriptions.Item label="Color (hex)">
        <Space size={'small'} direction={'horizontal'}>
          <Col style={{ backgroundColor: detail.hexColor }}
               className={`visible rounded-[50%] h-[20px] w-[20px] border border-grey-dkt-600`} />
          <span>{detail.hexColor}</span>
        </ Space>
      </Descriptions.Item>
      <Descriptions.Item label="Tienda de origen">
        {stores.find((store) => store.id === detail.originStoreId)?.value}
      </Descriptions.Item>
      <Descriptions.Item label={"Número de servicio"}>
        {detail.serviceNumber}
      </Descriptions.Item>
      <Descriptions.Item label="Restricciones" span={2}>
        {detail.restrictions}
      </Descriptions.Item>
      <Descriptions.Item label="Destinos"
                         span={2}
                         className={'[&.ant-descriptions-item-content]:p-0'}>
        <Col className={'grid grid-cols-5 p-2 bg-[#EFF1F3] [&>div]:text-center border border-[#D5D5D5] font-semibold'}>
          <Col>Código</Col>
          <Col>Precinto</Col>
          <Col>Fecha de descargue</Col>
          <Col>Tienda destino</Col>
          <Col>Restricciones</Col>
        </Col>

        {destinationList.map((destination) =>
          <Col className={'grid grid-cols-5 p-2 [&>div]:text-center [&>div]:truncate'}
               key={destination.destinationStoreId}>
            <Col title={destination.serial}>{destination.serial}</Col>
            <Col title={destination.sealNumber}>{destination.sealNumber}</Col>
            <Col title={destination.unloadDateTime}>{destination.unloadDateTime}</Col>
            <Col title={destination.destinationStoreId}>{destination.destinationStoreId}</Col>
            <Col title={destination.restrictions}>{destination.restrictions}</Col>
          </Col>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default GeneralInformation;
