// Libraries
import React, {useEffect} from 'react';
import {Row, Form, Modal} from 'antd';
import {useAppDispatch} from '../../store/store';
import {useSelector} from "react-redux";

// Store
import * as transportSelectors from "../../store/redux/selectors/transportSelectors";
import {
  addCarrierResponse,
  getCarrierResponse,
  updateCarrierResponse
} from "../../store/redux/actions/transportActions";

// Types
import {CarrierResponseType} from "../../types/transportTypes";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import SelectComponent from "../subComponents/SelectComponent";
import {currencyFormat} from "../../utils/functions";


type PropsType = {
  transportId: string | null;
  onToggleModal: (visible: boolean, transportId: string | null) => void;
};
const TransportFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { transportId, onToggleModal } = props;
  const { carTypes } = useSelector(transportSelectors.transportParamsSelector);
  const carrierResponse = useSelector(transportSelectors.carrierResponseSelector);

  useEffect(() => {
    dispatch(getCarrierResponse(transportId ?? ''));
  }, []);

  useEffect(() => {
    form.setFieldsValue(carrierResponse);
  }, [carrierResponse]);

  const handleSubmit = (response: CarrierResponseType) => {
    response.transportEngineId = transportId ?? '';
    if (carrierResponse.serviceValue) {
      response.serviceValue = carrierResponse.serviceValue;
      dispatch(updateCarrierResponse(carrierResponse.carrierResponseId, response));
    } else {
      dispatch(addCarrierResponse(response));
    }
    onToggleModal(false, null);
  }

  return (
    <Modal title={`Respuesta de transportista`}
           width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, null)} />,

             <ButtonComponent key={'submit'} text={`Enviar`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          <FormItemComponent name={'vehicleType'}
                             label={'Tipo de vehículo'}
                             required={true}
                             child={<SelectComponent list={carTypes} />}
          />

          <FormItemComponent name={'carPlate'}
                             label={'Placa del vehículo'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'driverName'}
                             label={'Nombre completo del conductor'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'driverIdentification'}
                             label={'Identificación del conductor'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'driverPhone'}
                             label={'Teléfono del conductor'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'driverArl'}
                             label={'ARL del conductor'}
                             required={true}
                             child={<InputComponent />}
          />

          {carrierResponse.serviceValue
            ? <span className={'mt-3'}>
              <b>Valor del servicio: </b> $ {currencyFormat(carrierResponse.serviceValue.toString())}
            </span>
            : <FormItemComponent name={'serviceValue'}
                                 label={'Valor del servicio'}
                                 required={true}
                                 withValueValidator={true}
                                 child={<InputComponent type={'currency'} />}
            />
          }
        </Row>
      </Form>
    </Modal>
  );
}

export default TransportFormModal;