import React, {useEffect, useState} from 'react';
import {Col, Popconfirm, Space, Tooltip} from 'antd';
import {
  CheckSquareOutlined,
  DeleteRowOutlined,
  EyeOutlined,
  FormOutlined,
  NodeCollapseOutlined,
  ProfileOutlined,
  PullRequestOutlined,
  StopOutlined,
  SubnodeOutlined
} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import dayjs from "dayjs";

// Store
import {useAppDispatch} from '../../store/store';
import * as transportSelectors from '../../store/redux/selectors/transportSelectors';
import {getParams, authorizedEntry, atOriginTerminal} from '../../store/redux/actions/transportActions';

// Types
import {TransportType, TransportPaginationType, DestinationType} from '../../types/transportTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import FiltersComponent from "../subComponents/FiltersComponent";

// Utils
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';
import {DATETIME_FORMAT, END_DATE_FORMAT, ROLES, START_DATE_FORMAT, TRANSPORT_STATES} from "../../utils/constants";
import {routes} from "../../utils/routes";
import {getLocalStorageItem} from "../../utils/functions";


type PropsType = {
  onToggleDetailModal: (visible: boolean, transportId: string) => void;
  onToggleCarrierResponseModal: (visible: boolean, transportId: string) => void;
  onToggleAddDetailModal: (visible: boolean, transportId: string, destinations: DestinationType[]) => void;
  onToggleReturnDetailModal: (visible: boolean, transportId: string) => void;
  onToggleReasonModal: (visible: boolean, transportId: string, isCancelling: boolean) => void;
  onToggleReassignCarrierModalVisible: (visible: boolean, transportId: string) => void;
}
const TransportTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const {
    onToggleDetailModal, onToggleCarrierResponseModal, onToggleAddDetailModal,
    onToggleReturnDetailModal, onToggleReasonModal, onToggleReassignCarrierModalVisible
  } = props;
  const [transportList, setTransportList] = useState<TransportType[]>([]);
  const [paginationData, setPaginationData] = useState<TransportPaginationType>({
    pagination: { page: 1, size: 10 },
    filter: { },
  });
  const { states, stores } = useSelector(transportSelectors.transportParamsSelector);
  const { rows, count, size, page } = useSelector(transportSelectors.allTransportsSelector);
  const filterParameters = getFilterParameters('transport');
  const renderRole = getLocalStorageItem('renderRole');
  const allowedModules = ROLES[renderRole as keyof typeof ROLES].allowedModules as any[];
  const permissions = allowedModules.find(module => module.name === routes.transport)!.permissions!;

  useEffect(() => {
    const newPaginationData = paginationData;

    if (paginationData.filter.dates) {
      newPaginationData.filter.initialDate = dayjs(paginationData.filter.dates[0]).format(START_DATE_FORMAT);
      newPaginationData.filter.finalDate = dayjs(paginationData.filter.dates[1]).format(END_DATE_FORMAT);
      paginationData.filter.dates = undefined;
    }

    dispatch(getParams(newPaginationData));
  }, [paginationData]);

  useEffect(() => {
    const data: TransportType[] = [];

    rows.map((transport) => {
      const destinationIds = transport.destinations?.map((destination) => destination.destinationStoreId);
      data.push({
        ...transport,
        startDatetime: dayjs(transport.startDatetime).format(DATETIME_FORMAT),
        state: states.find((state) => state.id === transport.state)?.value as string,
        destinationIds: destinationIds?.reduce((acc: string[], destinationId: any) => {
          acc = [...acc, stores.find((store) => store.id === destinationId)!.value as string];

          return acc;
        }, []),
      });
    });

    setTransportList(data);
  }, [rows]);

  const handleServiceDetail = (transportId: string) => {
    const destinations = transportList.find(({transportEngineId}) => transportEngineId === transportId)?.destinations ?? [];
    onToggleAddDetailModal(true, transportId, destinations);
  }

  const actionButtons = (transportId: string, stateName: string) => {
    const stateId = states.find((state) => state.value === stateName)?.id;
    const canFinishService = rows.find((transport) => transport.transportEngineId === transportId)?.canFinishService;
    const withReturn = rows.find((transport) => transport.transportEngineId === transportId)?.withReturn;

    return (
      <Space>
        <Tooltip title={'Ver detalle'}>
          <EyeOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                       onClick={() => onToggleDetailModal(true, transportId)} />
        </Tooltip>
        {permissions.canRespond && (stateId === TRANSPORT_STATES.solicitado || stateId === TRANSPORT_STATES.confirmado
          || stateId === TRANSPORT_STATES.conIngreso) &&
          <Tooltip title={'Respuesta de transportista'}>
            <FormOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                          onClick={() => onToggleCarrierResponseModal(true, transportId)} />
          </Tooltip>
        }
        {permissions.reassignCarrier && stateId === TRANSPORT_STATES.solicitado &&
          <Tooltip title={'Reasignar transportista'}>
            <PullRequestOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                                 onClick={() => onToggleReassignCarrierModalVisible(true, transportId)} />
          </Tooltip>
        }
        {permissions.canAuthorize && stateId === TRANSPORT_STATES.confirmado &&
          <Tooltip title={'Autorizar entrada'}>
            <Popconfirm title={'¿Seguro que quiere autorizar la entrada de este servicio?'}
                        okText={"Confirmar"}
                        onConfirm={() => dispatch(authorizedEntry(transportId))}>
              <CheckSquareOutlined className={'cursor-pointer hover:text-blue-dkt-400'} />
            </Popconfirm>
          </Tooltip>
        }
        {permissions.checkAtOriginTerminal && stateId === TRANSPORT_STATES.conIngreso &&
          <Tooltip title={'En la terminal de origen'}>
            <Popconfirm title={'¿Confirma que está en la terminal de origen?'}
                        okText={"Confirmar"}
                        onConfirm={() => dispatch(atOriginTerminal(transportId))}>
              <SubnodeOutlined className={'cursor-pointer hover:text-blue-dkt-400'} />
            </Popconfirm>
          </Tooltip>
        }
        {permissions.canCancel &&
          (stateId === TRANSPORT_STATES.solicitado || stateId === TRANSPORT_STATES.confirmado || stateId === TRANSPORT_STATES.conIngreso) &&
          <Tooltip title={'Cancelar servicio'}>
            <StopOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                          onClick={() => onToggleReasonModal(true, transportId, true)} />
          </Tooltip>
        }
        {permissions.addTransportDetail && stateId === TRANSPORT_STATES.enMuelle &&
          <Tooltip title={'Agregar detalle del servicio'}>
            <ProfileOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                             onClick={() => handleServiceDetail(transportId)} />
          </Tooltip>
        }
        {canFinishService && stateId === TRANSPORT_STATES.enCamino &&
          <Tooltip title={'Finalizar servicio'}>
            <DeleteRowOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                               onClick={() => onToggleReasonModal(true, transportId, false)} />
          </Tooltip>
        }
        {permissions.addReturnDetail && stateId === TRANSPORT_STATES.enCamino && withReturn &&
          <Tooltip title={'Agregar detalle del retorno'}>
            <NodeCollapseOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                                  onClick={() => onToggleReturnDetailModal(true, transportId)} />
          </Tooltip>
        }
      </Space>
    )
  };

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <FiltersComponent filterParameters={filterParameters}
                          selectList={states}
                          onChangeFilters={(values) => setPaginationData({
                            pagination: { page, size },
                            filter: values
                          })} />
      </Col>

      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={transportList}
                      scrollX={1100}
                      rowKey={'transportEngineId'}
                      columns={getTableColumns('transports', actionButtons)}
                      onChangePage={(pagination) => setPaginationData({
                        pagination: pagination,
                        filter: paginationData.filter
                      })} />
    </>
  );
}

export default TransportTable;